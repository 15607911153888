<template>
  <div class="eti-page-navigation u-text-align--left">
    <h3 class="headline--6 u-margin-top--1-5 u-padding-top--0-5">
      WHAT'S ON THIS PAGE
    </h3>
    <ETINavItemList
      v-if="overviewMenuItemsAtPageLoad.length > 0"
      id="overview-and-itinerary"
      :navItems="overviewMenuItemsAtPageLoad"
      title="Overview and itinerary"
      data-cy="eti-sub-nav-overview-item-list"
    />
    <ETINavItemList
      v-if="essentialMenuItemsAtPageLoad.length > 0"
      id="essential-trip-information"
      :navItems="essentialMenuItemsAtPageLoad"
      title="Essential Trip Information"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { ETIPageSubNavigationProps } from "./props";
import ETINavItemList from "./ETINavItemList.vue";
export default Vue.extend({
  name: "ETIPageNavigation",
  components: {
    ETINavItemList,
  },
  data() {
    return {
      essentialMenuItemsAtPageLoad: [] as ETIPageSubNavigationProps[],
      overviewMenuItemsAtPageLoad: [] as ETIPageSubNavigationProps[],
    };
  },
  mounted() {
    this.setAnchorTags();
  },
  methods: {
    setAnchorTags() {
      const anchorTags = document.querySelectorAll("[data-anchor-text]");

      anchorTags.forEach((element) => {
        const id = element.getAttribute("id");
        const anchorText = element.getAttribute("data-anchor-text");
        const type = id?.split("-")[0];

        if (id && anchorText && type) {
          if (type === "essential") {
            this.essentialMenuItemsAtPageLoad.push({
              id,
              anchorText,
              type,
            });
          } else {
            this.overviewMenuItemsAtPageLoad.push({
              id,
              anchorText,
              type,
            });
          }
        }
      });
    },
  },
});
</script>
<style lang="scss">
@import "./eti-page-navigation";
</style>
