<template>
  <Faqs :faqs="faqs" data-cy="faqs-panel" />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { FAQsPanelProps } from "./Props";
import Faqs from "~/components/Faqs/Faqs.vue";
export default Vue.extend({
  name: "FAQsPanel",
  components: {
    Faqs,
  },
  props: {
    faqs: {
      type: Array as PropType<FAQsPanelProps[]>,
      required: true,
    },
  },
});
</script>
