<template>
  <Page v-bind="page" class="eti-page">
    <ETIHeading :tagline="tagline" :review-aggregate="reviewAggregate" />
    <ETIUtility :trip-url="tripUrl" />
    <ErrorBoundary class="l-container">
      <div class="l-grid">
        <ETIPageNavigation
          class="
            l-grid__cell
            l-grid__cell--3-col
            l-grid__cell--12-col-phone
            l-grid__cell--4-col-tablet
          "
        />
        <ErrorBoundary
          class="
            l-grid__cell
            l-grid__cell--9-col
            l-grid__cell--12-col-phone
            l-grid__cell--8-col-tablet
            u-margin-top--2
            md:u-margin-top--1
          "
        >
          <TripEffectiveAlert
            v-if="tripEffectiveAlert"
            v-bind="tripEffectiveAlert"
            class="eti-effective-alert"
            is-eti
          />
          <ErrorBoundary
            v-if="productThemes?.length"
            class="
              eti-page__theme-product-messaging
              u-margin-top--1-5
              u-margin-bottom--4
            "
          >
            <template v-for="(productTheme, index) in productThemes">
              <ThemeProductMessaging v-bind="productTheme" :key="index" />
            </template>
          </ErrorBoundary>
          <h2
            id="overview-and-itinerary"
            class="eti-page__section-heading u-padding-bottom--1-5"
          >
            Overview and itinerary
          </h2>

          <ErrorBoundary>
            <TripSummary v-if="tripSummary" v-bind="tripSummary" />
          </ErrorBoundary>

          <ErrorBoundary
            v-if="productInformation"
            data-cy="product-information"
          >
            <h3
              v-if="productInformation?.ittrfy?.description"
              id="overview-right-trip"
              class="eti-page__section-heading"
              data-anchor-text="Is this trip right for you?"
            >
              Is this trip right for you?
            </h3>
            <RichText
              v-if="productInformation?.ittrfy?.description"
              :html="productInformation?.ittrfy?.description"
            />
            <h3
              v-if="
                tripSummary?.physicalRating ||
                productInformation.physicalRatingDescription
              "
              id="overview-physical-rating"
              class="eti-page__section-heading"
              data-anchor-text="Physical rating"
            >
              Physical rating
            </h3>
            <Rating
              v-if="tripSummary?.physicalRating"
              :rating="tripSummary.physicalRating"
              type="rectangle"
            ></Rating>
            <RichText
              v-if="productInformation.physicalRatingDescription"
              :html="productInformation.physicalRatingDescription"
            />
            <ErrorBoundary
              v-if="productInformation.joiningPoint"
              id="overview-joining-point"
              class="eti-page__section-heading"
              :data-anchor-text="joiningPointName"
            >
              <h3>{{ joiningPointName }}</h3>
              <ProductPoint
                :pointType="joiningPointName"
                v-bind="productInformation.joiningPoint"
              />
            </ErrorBoundary>
            <ErrorBoundary
              v-if="productInformation.alternateJoiningPoints"
              id="overview-alternate-joining-point"
              class="eti-page__section-heading"
              :data-anchor-text="
                `Alternate joining ` +
                $tc('point', productInformation.alternateJoiningPoints.length)
              "
            >
              <h3>
                Alternate joining
                {{
                  $tc("point", productInformation.alternateJoiningPoints.length)
                }}
              </h3>
              <ProductPoint
                v-for="(
                  item, index
                ) in productInformation.alternateJoiningPoints"
                :key="index"
                :pointType="alternateJoiningPointName"
                v-bind="item"
                :heading="alternateJoiningPointHeading(index)"
              />
            </ErrorBoundary>
            <ErrorBoundary
              v-if="productInformation.continuingPoints"
              id="overview-continuing-point"
              class="eti-page__section-heading"
              :data-anchor-text="
                `Continuing ` +
                $tc('point', productInformation.continuingPoints.length)
              "
            >
              <h3>
                Continuing
                {{ $tc("point", productInformation.continuingPoints.length) }}
              </h3>
              <ProductPoint
                v-for="(item, index) in productInformation.continuingPoints"
                :key="index"
                :pointType="continuingPointName"
                :heading="continuingPointHeading(index)"
                v-bind="item"
              />
            </ErrorBoundary>
            <ErrorBoundary
              v-if="productInformation.alternateContinuingPoints"
              id="overview-alternate-continuing-point"
              class="eti-page__section-heading"
              :data-anchor-text="
                `Alternate continuing ` +
                $tc(
                  'point',
                  productInformation.alternateContinuingPoints.length
                )
              "
            >
              <h3>
                Alternate continuing
                {{
                  $tc(
                    "point",
                    productInformation.alternateContinuingPoints.length
                  )
                }}
              </h3>
              <ProductPoint
                v-for="(
                  item, index
                ) in productInformation.alternateContinuingPoints"
                :key="index"
                v-bind="item"
                :pointType="alternateContinuingPointName"
                :heading="alternateContinuingPointHeading(index)"
              />
            </ErrorBoundary>
            <h3
              v-if="productInformation.problemsAndEmergencyContactInformation"
              id="overview-contact-information"
              class="eti-page__section-heading"
              data-anchor-text="Problems and emergency contact information"
            >
              Problems and emergency contact information
            </h3>
            <RichText
              v-if="productInformation.problemsAndEmergencyContactInformation"
              :html="productInformation.problemsAndEmergencyContactInformation"
            />
          </ErrorBoundary>

          <ErrorBoundary>
            <TripItinerary
              v-if="itinerary && itinerary.length"
              id="overview-trip-itinerary"
              class="eti-page__section-heading"
              data-anchor-text="Itinerary"
              :itinerary="itinerary"
            />
          </ErrorBoundary>

          <ErrorBoundary
            v-if="productInformation"
            data-cy="product-information"
          >
            <div
              v-if="productInformation.finishingPoint"
              id="overview-finishing-point"
              class="eti-page__section-heading"
              :data-anchor-text="finishingPointName"
            >
              <h3>{{ finishingPointName }}</h3>
              <ProductPoint
                :pointType="finishingPointName"
                v-bind="productInformation.finishingPoint"
              />
            </div>
            <ErrorBoundary
              v-if="productInformation.alternateFinishingPoints"
              id="overview-alternate-finishing-point"
              class="eti-page__section-heading"
              :data-anchor-text="
                `Alternate finishing ` +
                $tc('point', productInformation.alternateFinishingPoints.length)
              "
            >
              <h3>
                Alternate finishing
                {{
                  $tc(
                    "point",
                    productInformation.alternateFinishingPoints.length
                  )
                }}
              </h3>
              <ProductPoint
                v-for="(
                  item, index
                ) in productInformation.alternateFinishingPoints"
                :key="index"
                v-bind="item"
                :pointType="alternateFinishingPointName"
                :heading="alternateFinishingPointHeading(index)"
              />
            </ErrorBoundary>
            <h3
              v-if="productInformation.itineraryDisclaimer"
              id="overview-itinerary-disclaimer"
              class="eti-page__section-heading"
              data-anchor-text="Itinerary disclaimer"
            >
              Itinerary disclaimer
            </h3>
            <RichText
              v-if="productInformation.itineraryDisclaimer"
              :html="productInformation.itineraryDisclaimer"
            />
            <h3
              v-if="productInformation.feedback"
              id="overview-feedback"
              class="eti-page__section-heading"
              data-anchor-text="Feedback"
            >
              Feedback
            </h3>
            <RichText
              v-if="productInformation.feedback"
              :html="productInformation.feedback"
            />
            <h2
              id="essential-trip-information"
              class="eti-page__section-heading"
            >
              Essential Trip Information
            </h2>
            <h3
              v-if="productInformation.importantNotes"
              id="essential-important-notes"
              class="eti-page__section-heading"
              data-anchor-text="Important notes"
            >
              Important notes
            </h3>
            <RichText
              v-if="productInformation.importantNotes"
              :html="productInformation.importantNotes"
            />
            <h3
              v-if="productInformation.visas"
              id="essential-passports-visas"
              class="eti-page__section-heading"
              data-anchor-text="Passports, visas and entry requirements"
            >
              Passports, visas and entry requirements
            </h3>
            <RichText
              v-if="productInformation.visas"
              :html="productInformation.visas"
            />
            <h3
              v-if="productInformation.medicalAndHealthInformation"
              id="essential-medical-information"
              class="eti-page__section-heading"
              data-anchor-text="Medical and health information"
            >
              Medical and health information
            </h3>
            <RichText
              v-if="productInformation.medicalAndHealthInformation"
              :html="productInformation.medicalAndHealthInformation"
            />
            <h3
              v-if="productInformation.foodAndDietaryRequirements"
              id="essential-food-requirements"
              class="eti-page__section-heading"
              data-anchor-text="Food and dietary requirements"
            >
              Food and dietary requirements
            </h3>
            <RichText
              v-if="productInformation.foodAndDietaryRequirements"
              :html="productInformation.foodAndDietaryRequirements"
            />
            <h3
              v-if="accommodations || productInformation.accommodationNotes"
              id="essential-accommodation"
              class="eti-page__section-heading"
              data-anchor-text="Accommodation"
            >
              Accommodation
            </h3>
            <p v-if="accommodations">{{ accommodations }}</p>
            <RichText
              v-if="productInformation.accommodationNotes"
              :html="productInformation.accommodationNotes"
            />
            <h3
              v-if="transport || productInformation.transportNotes"
              id="essential-transport"
              class="eti-page__section-heading"
              data-anchor-text="Transport"
            >
              Transport
            </h3>
            <p v-if="transport">{{ transport }}</p>
            <RichText
              v-if="productInformation.transportNotes"
              :html="productInformation.transportNotes"
            />
            <h3
              v-if="productInformation.moneyMatters"
              id="essential-money-matters"
              class="eti-page__section-heading"
              data-anchor-text="Money matters"
            >
              Money matters
            </h3>
            <RichText
              v-if="productInformation.moneyMatters"
              :html="productInformation.moneyMatters"
            />
            <h3
              v-if="productInformation.packing"
              id="essential-packing"
              class="eti-page__section-heading"
              data-anchor-text="Packing"
            >
              Packing
            </h3>
            <RichText
              v-if="productInformation.packing"
              :html="productInformation.packing"
            />
            <h3
              v-if="productInformation.communications"
              id="essential-phone-and-internet-access"
              class="eti-page__section-heading"
              data-anchor-text="Phone and internet access"
            >
              Phone and internet access
            </h3>
            <RichText
              v-if="productInformation.communications"
              :html="productInformation.communications"
            />
            <h3
              v-if="productInformation.climateAndSeasonalInformation"
              id="essential-seasonal-information"
              class="eti-page__section-heading"
              data-anchor-text="Climate and seasonal information"
            >
              Climate and seasonal information
            </h3>
            <RichText
              v-if="productInformation.climateAndSeasonalInformation"
              :html="productInformation.climateAndSeasonalInformation"
            />
            <h3
              v-if="productInformation.groupLeader"
              id="essential-group-leader"
              class="eti-page__section-heading"
              data-anchor-text="Group Leader"
            >
              Group Leader
            </h3>
            <RichText
              v-if="productInformation.groupLeader"
              :html="productInformation.groupLeader"
            />
            <h3
              v-if="productInformation.safety"
              id="essential-safety"
              class="eti-page__section-heading"
              data-anchor-text="Safety"
            >
              Safety
            </h3>
            <RichText
              v-if="productInformation.safety"
              :html="productInformation.safety"
            />
            <h3
              v-if="productInformation.communityGuidelines"
              id="essential-community-guidelines"
              class="eti-page__section-heading"
              data-anchor-text="Community guidelines"
            >
              Community guidelines
            </h3>
            <RichText
              v-if="productInformation.communityGuidelines"
              :html="productInformation.communityGuidelines"
            />
            <h3
              v-if="productInformation.travellingInAGroup"
              id="essential-group-trip"
              class="eti-page__section-heading"
              data-anchor-text="Travelling on a group trip"
            >
              Travelling on a group trip
            </h3>
            <RichText
              v-if="productInformation.travellingInAGroup"
              :html="productInformation.travellingInAGroup"
            />
            <h3
              v-if="productInformation.travelInsurance"
              id="essential-travel-insurance"
              class="eti-page__section-heading"
              data-anchor-text="Travel insurance"
            >
              Travel insurance
            </h3>
            <RichText
              v-if="productInformation.travelInsurance"
              :html="productInformation.travelInsurance"
            />
            <h3
              v-if="productInformation.responsibleTravel"
              id="essential-responsible-travel"
              class="eti-page__section-heading"
              data-anchor-text="Responsible travel"
            >
              Responsible travel
            </h3>
            <RichText
              v-if="productInformation.responsibleTravel"
              :html="productInformation.responsibleTravel"
            />
            <h3
              v-if="productInformation.theIntrepidFoundation"
              id="essential-the-intrepid-foundation"
              class="eti-page__section-heading"
              data-anchor-text="The Intrepid Foundation"
            >
              The Intrepid Foundation
            </h3>
            <RichText
              v-if="productInformation.theIntrepidFoundation"
              :html="productInformation.theIntrepidFoundation"
            />
          </ErrorBoundary>
        </ErrorBoundary>
      </div>
    </ErrorBoundary>

    <ETIUtility :trip-url="tripUrl" />
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Rating from "atlas/src/components/Rating/Rating.vue";
import ProductPoint from "../ProductPoint/ProductPoint.vue";
import TripEffectiveAlert from "../TripEffectiveAlert/TripEffectiveAlert.vue";
import RichText from "../RichText/RichText.vue";
import ETIPageNavigation from "../ETIPageNavigation/ETIPageNavigation.vue";
import { ETIPageProps } from "./Props";
import Page from "~/components/Page/Page.vue";
import TripItinerary from "~~/components/TripItinerary/TripItinerary.vue";
import ETIHeading from "~/components/ETIHeading/ETIHeading.vue";
import ETIUtility from "~/components/ETIUtility/ETIUtility.vue";
import ErrorBoundary from "~/components/ErrorBoundary/ErrorBoundary.vue";
import { isEmpty } from "~~/lib/utils/isEmpty";
import ThemeProductMessaging from "~/components/ThemeProductMessaging/ThemeProductMessaging.vue";
import TripSummary from "~/components/TripSummary/TripSummary.vue";

export default Vue.extend({
  name: "ETIPage",
  components: {
    Page,
    ProductPoint,
    RichText,
    TripItinerary,
    ETIHeading,
    ETIUtility,
    TripEffectiveAlert,
    ThemeProductMessaging,
    TripSummary,
    ETIPageNavigation,
    Rating,
    ErrorBoundary,
  },
  props: {
    page: {
      type: Object as PropType<ETIPageProps["page"]>,
      required: true,
    },
    tripUrl: {
      type: String as PropType<ETIPageProps["tripUrl"]>,
      required: true,
    },
    tagline: {
      type: String as PropType<ETIPageProps["tagline"]>,
      required: true,
    },
    reviewAggregate: {
      type: Object as PropType<ETIPageProps["reviewAggregate"]>,
      required: false,
      default: undefined,
    },
    productInformation: {
      type: Object as PropType<ETIPageProps["productInformation"]>,
      required: false,
      default: undefined,
    },
    itinerary: {
      type: Array as PropType<ETIPageProps["itinerary"]>,
      required: false,
      default: undefined,
    },
    tripEffectiveAlert: {
      type: Object as PropType<ETIPageProps["tripEffectiveAlert"]>,
      required: false,
      default: undefined,
    },
    productThemes: {
      type: Array as PropType<ETIPageProps["productThemes"]>,
      required: false,
      default: undefined,
    },
    tripSummary: {
      type: Object as PropType<ETIPageProps["tripSummary"]>,
      required: true,
    },
    accommodations: {
      type: String as PropType<ETIPageProps["accommodations"]>,
      required: false,
      default: undefined,
    },
    transport: {
      type: String as PropType<ETIPageProps["transport"]>,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      joiningPointName: "Joining point",
      finishingPointName: "Finishing point",
      continuingPointName: "Continuing point",
      alternateJoiningPointName: "Alternate joining point",
      alternateFinishingPointName: "Alternate finishing point",
      alternateContinuingPointName: "Alternate continuing point",
    };
  },
  methods: {
    hasMoreThanOne(array: unknown[] | undefined): boolean {
      return (array ?? []).length > 1;
    },
    hasProductInformation(): boolean {
      return !isEmpty(this.productInformation);
    },
    alternateJoiningPointHeading(index: number): string | null {
      return this.hasMoreThanOne(
        this.productInformation?.alternateJoiningPoints
      )
        ? `${this.alternateJoiningPointName} ${index + 1}`
        : null;
    },
    continuingPointHeading(index: number): string | null {
      return this.hasMoreThanOne(this.productInformation?.continuingPoints)
        ? `${this.continuingPointName} ${index + 1}`
        : null;
    },
    alternateContinuingPointHeading(index: number): string | null {
      return this.hasMoreThanOne(
        this.productInformation?.alternateContinuingPoints
      )
        ? `${this.alternateContinuingPointName} ${index + 1}`
        : null;
    },
    alternateFinishingPointHeading(index: number): string | null {
      return this.hasMoreThanOne(
        this.productInformation?.alternateFinishingPoints
      )
        ? `${this.alternateFinishingPointName} ${index + 1}`
        : null;
    },
  },
});
</script>
<style lang="scss">
@import "./eti-page";
</style>
