<template>
  <div>
    <a
      v-if="navItems.length > 0"
      :href="`#${id}`"
      class="
        u-margin-top--1
        u-font-weight--bold
        u-color--text
        u-cursor--pointer
        u-text-decoration--none
      "
    >
      {{ title }}
    </a>
    <ul class="u-list-style-type--none u-padding-left--0 u-margin-top--0-5">
      <li
        v-for="(navItem, index) of navItems"
        :key="index"
        class="u-padding-left--0-5"
        :class="navItem.type == 'child' ? 'u-padding-left--1' : ''"
      >
        <a
          :href="`#${navItem.id}`"
          class="eti-navigation-item-list__item--link"
        >
          {{ navItem.anchorText }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { PropType } from "@vue/runtime-dom";
import Vue from "vue";
import { ETIPageSubNavigationProps } from "./props";

export default Vue.extend({
  name: "ETINavItemList",
  props: {
    navItems: {
      type: Array as PropType<ETIPageSubNavigationProps[]>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
});
</script>
<style lang="scss">
@import "./eti-navigation-item-list";
</style>
