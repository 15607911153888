<template>
  <Tile
    :title="title"
    :link="link"
    :button-text="buttonText"
    :icon="icon"
    class="tile tile--special tile--horizontal"
  >
    <p v-if="description" class="u-margin-bottom--0">{{ description }}</p>
  </Tile>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import { ETILinkProps } from "./Props";

export default Vue.extend({
  name: "ETILink",
  components: {
    Tile,
  },
  props: {
    title: {
      type: String as PropType<ETILinkProps["title"]>,
      required: true,
    },
    description: {
      type: String as PropType<ETILinkProps["description"]>,
      required: true,
    },
    link: {
      type: String as PropType<ETILinkProps["link"]>,
      required: true,
    },
    buttonText: {
      type: String as PropType<ETILinkProps["buttonText"]>,
      required: true,
    },
    icon: {
      type: String as PropType<ETILinkProps["icon"]>,
      required: true,
    },
  },
});
</script>
