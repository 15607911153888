<template>
  <div
    data-cy="eti-utility"
    class="
      eti-utility
      u-display--flex
      u-justify-content--space-between
      l-container
      u-padding-top--1-5
      u-padding-bottom--1-5
    "
  >
    <Button
      v-if="tripUrl"
      class="button--unboxed"
      :href="$link.create(tripUrl)"
      data-cy="back-to-trip"
    >
      <Icon
        name="arrow-right"
        class="u-margin-right--1"
        flip="horizontal"
      ></Icon>
      Back to trip
    </Button>

    <Button
      class="button--secondary"
      data-cy="print-button"
      :on-click="printWindow"
    >
      <Icon name="print" class="u-margin-right--1"></Icon>
      Print
    </Button>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import "atlas/src/components/Icon/icons/print.js";
import "atlas/src/components/Icon/icons/arrow-right.js";
import { ETIUtilityProps } from "./Props";

export default Vue.extend({
  name: "ETIUtility",
  components: {
    Button,
    Icon,
  },
  props: {
    tripUrl: {
      type: String as PropType<ETIUtilityProps["tripUrl"]>,
      required: true,
    },
  },
  methods: {
    printWindow(): void {
      window.print();
    },
  },
});
</script>
