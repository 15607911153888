<template>
  <Page v-bind="page">
    <template #alert>
      <SalesRegionAlert v-if="showSalesRegionAlert" />
    </template>
    <ErrorBoundary
      v-if="introduction"
      class="
        l-container l-container--small
        u-margin-top--2
        sm:u-margin-top--6
        u-margin-bottom--4
      "
    >
      <Introduction v-if="introduction" v-bind="introduction" id="overview" />
    </ErrorBoundary>
    <div class="l-container">
      <ErrorBoundary v-if="panels">
        <Panels
          v-bind="panels"
          class="u-margin-top--4 sm:u-margin-top--6"
          data-cy="engagement-panels"
        />
      </ErrorBoundary>
      <ErrorBoundary v-if="termsAndConditions">
        <TermsAndConditions
          :termsAndConditions="termsAndConditions"
          class="u-margin-top--4 sm:u-margin-top--6"
        />
      </ErrorBoundary>
    </div>
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { EngagementPageProps } from "./Props";
import TermsAndConditions from "~/components/TermsAndConditions/TermsAndConditions.vue";
import Page from "~~/components/Page/Page.vue";
import ErrorBoundary from "~~/components/ErrorBoundary/ErrorBoundary.vue";
import Introduction from "~/components/Introduction/Introduction.vue";
import Panels from "~/components/Panels/Panels.vue";
import SalesRegionAlert from "~/components/SalesRegionAlert/SalesRegionAlert.vue";

export default Vue.extend({
  name: "EngagementPage",
  components: {
    Page,
    ErrorBoundary,
    Introduction,
    Panels,
    SalesRegionAlert,
    TermsAndConditions,
  },
  props: {
    page: {
      type: Object as PropType<EngagementPageProps["page"]>,
      required: true,
    },
    introduction: {
      type: Object as PropType<EngagementPageProps["introduction"]>,
      required: false,
      default: undefined,
    },
    panels: {
      type: Object as PropType<EngagementPageProps["panels"]>,
      required: false,
      default: undefined,
    },
    termsAndConditions: {
      type: Array as PropType<EngagementPageProps["termsAndConditions"]>,
      required: false,
      default: undefined,
    },
    showSalesRegionAlert: {
      type: Boolean as PropType<EngagementPageProps["showSalesRegionAlert"]>,
      required: false,
      default: false,
    },
  },
});
</script>
