<template>
  <div
    data-cy="eti-heading"
    class="
      eti-heading
      u-padding-top--1-5
      u-padding-bottom--1-5
      u-background-color--sand
    "
  >
    <div class="l-container">
      <h2
        class="
          eti-heading__title
          headline--4
          u-padding-bottom--1
          u-margin-top--0
          u-margin-bottom--0
          xs:u-text-align--left
          sm:u-text-align--center
        "
        data-cy="eti-heading__title"
      >
        {{ tagline }}
      </h2>
      <ReviewAggregate
        v-if="reviewAggregate && reviewAggregate.reviewsCount"
        :show-info="false"
        :average-rating="reviewAggregate.averageRating"
        :reviews-count="reviewAggregate.reviewsCount"
        class="review-aggregate--center sm:u-justify-content--center"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { ETIHeadingProps } from "./Props";
import ReviewAggregate from "~/components/ReviewAggregate/ReviewAggregate.vue";

export default Vue.extend({
  name: "ETIHeading",
  components: {
    ReviewAggregate,
  },
  props: {
    tagline: {
      type: String as PropType<ETIHeadingProps["tagline"]>,
      required: true,
    },
    reviewAggregate: {
      type: Object as PropType<ETIHeadingProps["reviewAggregate"]>,
      required: false,
      default: undefined,
    },
  },
});
</script>
